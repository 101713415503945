import { Component, Input } from '@angular/core'
import { EmmaAlertMessage } from '@emma/type-definitions/types'
import { EmmaService } from '@emmaServices/emma.service'
import { NotificationService } from '@services/notification.service'
import { SystemNotification } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'

@Component({
  selector: 'app-notification-list-item',
  templateUrl: './notification-list-item.component.html',
  styleUrls: ['./notification-list-item.component.scss'],
})
export class NotificationListItemComponent {
  @Input()
  notification: SystemNotification

  constructor(
    public proficloud: ProficloudService,
    public notificationService: NotificationService,
    public emma: EmmaService
  ) {}

  public asEmma(notification: SystemNotification): EmmaAlertMessage {
    return notification.serviceNotification as EmmaAlertMessage
  }

  getRouterLink(service: string): string[] {
    switch (service) {
      case 'user-management':
        return ['/services/user-management-service/users']
      case 'device-management':
        return ['/services/device-management/list']
      case 'emma':
        return ['/services/energy-management/alerts']
      default:
        return ['/']
    }
  }

  getServiceIcon(service: string): string {
    switch (service) {
      case 'user-management':
        return 'userManagement'
      case 'device-management':
        return 'device'
      case 'emma':
        return 'emma'
      case 'emma-reporting':
        return 'emma'
      default:
        return 'unknown'
    }
  }

  openNotification($event: MouseEvent) {
    if (this.notification.serviceNotification.responsible === 'emma') {
      this.emma.acknowledgeMessage$.next(this.notification.serviceNotification.id)
    }
    this.notificationService.showNotifications$.next(false)
    this.notificationService.dismissNotification(this.notification)
    $event.stopPropagation()
  }
}
