import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { DeviceBillingSubscription } from '@dms/services/device-billing.service'
import { DeviceService } from '@dms/services/device.service'
import { EmmaService } from '@emmaServices/emma.service'
import { TranslateService } from '@ngx-translate/core'
import { BillingService } from '@services/billing/billing.service'
import { BillingAccount, CreatedSubscription, ServiceIds, SubscriptionResponse } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { PcStatusOverlayService } from '@shared/services/pc-status-overlay/pc-status-overlay.service'
import { PCOverlayConfig, PCStatusOverlayConfig } from '@shared/type-definitions/types'
import { AppService } from 'src/app/app.service'
import { BillingStore } from '../../../stores/billing.store'

@Component({
  selector: 'app-services-root',
  styleUrls: ['./services-root.component.scss'],
  templateUrl: './services-root.component.html',
})
export class ServicesRootComponent {
  requiredDialog: PCOverlayConfig = {
    title: 'STORE.ROUTE_TITLE_FILL_BILLING_INFO',
  }

  checkoutDialog: PCOverlayConfig = {
    title: 'STORE.ROUTE_TITLE_CHECKOUT_DIALOG',
  }

  confirmationDialog: PCOverlayConfig = {
    title: 'STORE.ROUTE_TITLE_CONFIRMATION_DIALOG',
  }

  successConfirmationDialog: PCOverlayConfig = {
    title: 'STORE.ORDER_SUCCESS',
  }

  failConfirmationDialog: PCOverlayConfig = {
    title: 'STORE.ORDER_FAILED',
  }

  termsAccepted = false

  upgradeTermsAccepted = false
  stripeResponse: SubscriptionResponse
  showStripeSuccess: boolean = false
  showStripeFail: boolean = false
  bookedSubscription: CreatedSubscription
  constructor(
    public billing: BillingService,
    public billingStore: BillingStore,
    public deviceService: DeviceService,
    public emma: EmmaService,
    public app: AppService,
    public translate: TranslateService,
    public proficloud: ProficloudService,
    public ui: UiService,
    private route: ActivatedRoute,
    private router: Router,
    private statusOverlay: PcStatusOverlayService
  ) {
    ;(window as any).root = this
  }

  /**
   *   The user has been informed that we require billing information, and clicked 'continue'
   */
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.showStripeSuccess = params['showStripeSuccess'] === 'true'
      this.showStripeFail = params['showStripeFail'] === 'true'
    })
  }

  public closeDialog() {
    // TODO: This looks shit, should not be navigating
    this.router.navigate(['/services/store'])
  }

  public onContinueClick() {
    this.billing.showBillingAddressRequired$.next(false)
    this.billing.showBillingAddressForm$.next(true)
  }

  /**
   *    The user submits a billing account. Once it has been created, and the success message disappeard
   */
  public onBillingAccountFormSubmit() {
    const billingAccountData: BillingAccount = {
      firstName: this.billing.billingAddressForm.value.firstname,
      lastName: this.billing.billingAddressForm.value.lastname,
      language: this.billing.getCountryLanguageCode(this.billing.billingAddressForm.value.country) || 'en-EN',
      // This should be prefilled with the organisation name if available
      // Companyname should also be renamed to Organization, this will happen once the billing service got refactored
      companyName: this.billing.billingAddressForm.value.companyname,
      emailAddress: this.billing.billingAddressForm.value.email,
      vatId: this.billing.billingAddressForm.value.vatNumber,
      pxcCustomerId: this.billing.billingAddressForm.value.pxcCustomerId,
      address: {
        street: this.billing.billingAddressForm.value.street,
        houseNumber: this.billing.billingAddressForm.value.houseNumber,
        addressLine1: this.billing.billingAddressForm.value.addressLine1,
        addressLine2: this.billing.billingAddressForm.value.addressLine2,
        city: this.billing.billingAddressForm.value.city,
        postalCode: this.billing.billingAddressForm.value.postal,
        state: this.billing.billingAddressForm.value.state,
        country: this.billing.billingAddressForm.value.country,
      },
    }
    this.billingStore.createBillingAccount(billingAccountData).subscribe((created) => {
      if (created) {
        this.billing.showCheckoutForm$.next(true)
      } else {
        this.billing.showCheckoutForm$.next(false)
      }
    })
  }

  /**
   *    The user want to complete a purchace
   */
  public submitCheckoutForm() {
    // clear the reference
    this.billing.referenceInput = ''
    const cart = this.billing.billingDetails.shoppingCart
    if (!cart?.plan?.fields.sapNumber || !cart?.quantity) {
      console.warn('Billing info missing')
      return
    }
    const fullUrl = `${window.location.protocol}//${window.location.host}${this.router.url}`

    const newPackage = {
      packageId: this.billing.billingDetails.shoppingCart?.plan?.fields.sapNumber as string,
      quantity: this.billing.billingDetails.shoppingCart?.quantity as number,
      sendNoConfirmationMail: false,
      stripeSuccessUrl: `${fullUrl}?showStripeSuccess=true`,
      stripeCancelUrl: `${fullUrl}?showStripeFail=true`,
      internalOrderId: this.billing.orderReferenceControl.value,
    }

    // show busy overlay
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.createSubscriptionBusy)

    // fire off create-subscription call
    if (this.billing.isStripeCountry(this.billing.currentCountry)) {
      // show redirecting to stripe
      this.statusOverlay.showStatus(this.proficloud.statusOverlays.createStripeBusy)

      this.billingStore.createSubscription(newPackage).subscribe({
        next: (response) => {
          this.bookedSubscription = response.subscription
          window.location.href = response.stripeCheckoutUrl as string

          this.statusOverlay.showStatus(this.proficloud.statusOverlays.createSubscriptionSuccess)

          setTimeout(() => this.statusOverlay.resetStatus(), 2000)
        },
        error: (err) => {
          // show error
          const errorMessage: PCStatusOverlayConfig = {
            type: 'error',
            message: 'An error occured while creating your stripe subscription.',
            closeable: true,
            extraInfo: err.error.error.includes('customer_tax_location_invalid') ? 'Invalid customer address.' : err.error.error.split('.')[0],
          }
          this.statusOverlay.showStatus(errorMessage)
        },
      })
    } else {
      this.billingStore.createSubscription(newPackage).subscribe({
        next: (bookedSubscription) => {
          // store booked subscription locally
          this.bookedSubscription = bookedSubscription.subscription

          // show success
          this.statusOverlay.showStatus(this.proficloud.statusOverlays.createSubscriptionSuccess)

          // close success dialog after 2 seconds
          setTimeout(() => this.statusOverlay.resetStatus(), 2000)

          // broadcast success
          this.billing.showCheckoutForm$.next(false)
          this.billing.showConfirmationForm$.next(true)
        },
        error: (error) => {
          this.proficloud.logoutOnUnauthorised(error)

          // show error
          this.statusOverlay.showStatus(this.proficloud.statusOverlays.createSubscriptionError)
        },
      })
    }
  }

  /**
   *    A purchase has been successfull create, the confirmation screen shown, and the user clicked on 'go to service'
   */
  public submitConfirmationForm() {
    // close confirmation screen
    this.billing.showConfirmationForm$.next(false)

    // redirect to the service url
    const redirectUrl = this.billing.billingDetails.shoppingCart?.service?.fields.redirection[this.app.environment.backend]
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }

  /** Helpers for 'assign metrics/devices' offering */
  public getAssignWording(service: ServiceIds = 'tsd') {
    const serviceWording: { [key in ServiceIds]?: string } = {
      tsd: 'Assign Metrics',
      ia: 'Assign Devices',
      emma: 'Assign Devices',
      dmsAddonBasic: 'Assign Devices',
      'tsd-prologis': 'Assign Devices',
    }
    return serviceWording[service || this.billing.bookedSubscription.smartServiceId]
  }

  public onAssignButtonClick() {
    if (this.billing.bookedSubscription.smartServiceId === 'tsd') {
      this.billing.showAssignMetricsModal$.next(true)
    }

    if (this.billing.bookedSubscription.smartServiceId === 'ia') {
      this.billing.showAssignDevicesModal$.next({
        subscription: this.billing.bookedSubscription as any,
        candidateDevices: this.billing.iaCapableDevices,
        service: 'impulse',
      })
    }

    if (this.billing.bookedSubscription.smartServiceId === 'dmsAddonBasic' && this.deviceService.devices) {
      this.billing.showAssignDevicesModal$.next({
        subscription: this.billing.bookedSubscription as any,
        candidateDevices: this.deviceService.devices,
        service: 'dmsAddonBasic',
        devicesUsed: (this.billing.bookedSubscription as DeviceBillingSubscription).endpointIds, // TODO (aschoenenberg): Needs to be changed because the endpointIds are missing after cast
      })
    }
  }

  public onConfirmUpgradeClick() {
    const packageId = this.billing.billingDetails.upgradePreview?.newSubscription.package.id
    if (!packageId) {
      console.warn('package ID missing')
      return
    }

    if (!this.billing.billingDetails.upgradePreview?.subscription.id) {
      console.warn('subscription ID missing')
      return
    }

    this.statusOverlay.showStatus(this.proficloud.statusOverlays.upgradeSubscriptionBusy)

    const bookingPackage = {
      quantity: 1,
      packageId: this.billing.billingDetails.upgradePreview?.newSubscription.package.id as string,
      internalOrderId: this.billing.upgradeReference || '',
    }

    this.billingStore.upgradeSubscription(this.billing.billingDetails.upgradePreview?.subscription.id, bookingPackage).subscribe({
      next: (response: any) => {
        // store booked subscription
        this.billing.upgradedSubscription = response

        // hide preview
        this.billing.showUpgradeDetails$.next(false)

        // if status = pending, show stripe msg
        if (response.status === 'PENDING') {
          this.statusOverlay.showStatus(this.proficloud.statusOverlays.upgradeStripeSubscriptionSuccess)

          // after 2 secs, hide msg and reload tsd subscriptions
          setTimeout(() => {
            this.statusOverlay.resetStatus()
          }, 2000)
        }
        // if status = committed,show success and upgrade confirmation
        else {
          this.statusOverlay.showStatus(this.proficloud.statusOverlays.upgradeSubscriptionSuccess)

          // after 2 seconds, hide success and show upgrade confirmation modal
          setTimeout(() => {
            this.statusOverlay.resetStatus()
            this.billing.showUpgradeConfirmationForm$.next(true)
          }, 2000)
        }
      },
      error: () => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.upgradeSubscriptionError)
      },
    })
  }

  public closeUpgradeDialog() {
    this.upgradeTermsAccepted = false
    this.billing.showUpgradeDetails$.next(false)
  }
}
