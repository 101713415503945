import { HttpErrorResponse } from '@angular/common/http'
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { DeviceBillingService } from '@dms/services/device-billing.service'
import { EmmaStore } from '@emma/stores/emma.store'
import { FeatureSetAndLimitsResponse } from '@emma/type-definitions/types'
import { EmmaService } from '@emmaServices/emma.service'
import { TranslateService } from '@ngx-translate/core'
import { AuthorisationService } from '@services/authorisation.service'
import { BillingService } from '@services/billing/billing.service'
import { IamService } from '@services/iam.service'
import { ExpansionStates, Member, Organisation, OrganisationRole, OrganisationTabs, ServiceIds } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { ITab } from '@shared/components/pc-tab-bar/pc-tab-bar.component'
import { PcStatusOverlayService } from '@shared/services/pc-status-overlay/pc-status-overlay.service'
import { sharedAnimations } from '@shared/shared.animations'
import { PCOverlayConfig } from '@shared/type-definitions/types'
import { Subject, Subscription } from 'rxjs'
import { take } from 'rxjs/operators'

@Component({
  selector: 'app-expandable-organisation',
  templateUrl: './expandable-organisation.component.html',
  styleUrls: ['./expandable-organisation.component.scss'],
  animations: [sharedAnimations.heightSlide],
})
export class ExpandableOrganisationComponent implements OnInit, OnDestroy {
  @Input()
  public organisation: Organisation

  @Input()
  public initialTab: OrganisationTabs

  @Input()
  public organisationMenuOpened$: Subject<any>

  @Input() active = false

  @Input()
  public authorised: boolean = true

  @Input()
  members: Member[]

  getNewUserInvited: Subscription

  membersLoaded: Subscription

  organizationMenuVisible = false

  ROLES = OrganisationRole

  panels: { [key in ServiceIds]: ExpansionStates } = {
    tsd: 'collapsed',
    ia: 'collapsed',
    emma: 'collapsed',
    dmsAddonBasic: 'collapsed',
    'tsd-prologis': 'collapsed',
    chargeRepay: 'collapsed',
    ems: 'collapsed',
  }

  tabs: ITab[] = [
    {
      name: this.translate.instant('USR_SETTINGS.INFORMATION_TAB'),
      id: 'information',
      selected: true,
    },
    {
      name: this.translate.instant('USR_SETTINGS.SUBSCRIPTION_TAB'),
      id: 'subscriptions',
    },
    {
      name: this.translate.instant('USR_SETTINGS.BILLING_INFORMATION_TAB'),
      id: 'billing',
    },
    {
      name: this.translate.instant('USR_SETTINGS.USERS_TAB'),
      id: 'users',
    },
  ]

  // leave organization dialog
  leaveOrgDialog: PCOverlayConfig = {
    title: 'Leave Organization',
  }

  showLeaveOrg = false

  // edit organization dialog
  // it's called edit because there will be more fields to change than the name only in the future.
  editOrgDialog: PCOverlayConfig = {
    title: 'Rename Organization',
  }

  editOrgForm: UntypedFormGroup

  editOrgNameControl: FormControl

  showEditOrg = false

  isActive = false

  public limits?: FeatureSetAndLimitsResponse

  constructor(
    public authorisation: AuthorisationService,
    public billing: BillingService,
    public cd: ChangeDetectorRef,
    public emma: EmmaService,
    private emmaStore: EmmaStore,
    public proficloud: ProficloudService,
    public iam: IamService,
    public router: Router,
    private statusOverlay: PcStatusOverlayService,
    public translate: TranslateService,
    public ui: UiService,
    public deviceBilling: DeviceBillingService
  ) {}

  ngOnInit(): void {
    this.editOrgNameControl = new UntypedFormControl(this.organisation.organizationName || '', [
      Validators.required,
      Validators.maxLength(256),
      Validators.pattern('^[-_.@, A-Za-z0-9]+$'),
    ])
    this.editOrgForm = new UntypedFormGroup({ orgName: this.editOrgNameControl })

    this.organisationMenuOpened$.subscribe((open) => {
      this.organizationMenuVisible = !open
    })

    this.deviceBilling.fetchDeviceBillingSubscriptions()

    this.emmaStore.limits$.subscribe({
      next: (limits) => {
        if (limits) {
          this.limits = limits
        }
      },
      error: (err) => {
        console.log(err)
      },
    })
  }

  public togglePanel(key: ServiceIds) {
    this.panels[key] = this.panels[key] === 'expanded' ? 'collapsed' : 'expanded'
  }

  public impulseSlotSummary() {
    let availableSlots = 0
    let usedSlots = 0
    this.billing.impulseAnalyticsSubscriptions.forEach((subscription) => {
      availableSlots += subscription.quantity
      usedSlots += subscription.devicesUsed.length
    })
    return { availableSlots, usedSlots }
  }

  public toggleExpansion() {
    this.organisation.expansionState = this.organisation.expansionState === 'expanded' ? 'collapsed' : 'expanded'
  }

  public bookedPackage(id: string) {
    return this.billing.emmaPackages.filter((p) => p.id === id)[0]
  }

  public forcedRole(role: OrganisationRole): OrganisationRole {
    return role
  }

  // public reloadMembers() {
  //   this.statusOverlay.showStatus(this.membersLoading)

  // }

  async toggleOrganizationMenu(event: MouseEvent) {
    this.organisationMenuOpened$.next(true)
    this.organizationMenuVisible ? (this.organizationMenuVisible = false) : (this.organizationMenuVisible = true)
    this.stopEventPropagation(event)
  }

  leaveOrganization() {
    const userId = this.proficloud.keycloakData.userDetails?.data.userId
    if (!userId) {
      console.warn('user id missing')
      return
    }

    this.statusOverlay.showStatus(this.proficloud.statusOverlays.leaveOrganizationBusy)

    this.iam.leaveIamOrganisation(this.organisation.organizationId, userId).subscribe({
      next: () => {
        this.showLeaveOrg = false
        // switch organization after leaving
        // There is no timeout, since there will be a switch organization follow with its own overlay
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.leaveOrganizationSuccess)
        this.statusOverlay.resetStatus()
        this.iam.organizationLeft$.next(null)
      },
      error: (err: HttpErrorResponse) => {
        if (err.error.error.id === 'iam.ORGANIZATION_USER_IS_LAST_ADMIN_ERROR') {
          this.showLeaveOrg = false
          this.statusOverlay.showStatus(this.proficloud.statusOverlays.leaveOrganizationLMError)
        } else {
          this.showLeaveOrg = false
          this.statusOverlay.showStatus(this.proficloud.statusOverlays.leaveOrganizationError)
        }
      },
    })
  }

  stopEventPropagation(event: MouseEvent) {
    event.stopPropagation()
    event.preventDefault()
  }

  openUMS() {
    this.router.navigate(['/services/user-management-service/users'])
  }

  openRenameOrgDialog(event: MouseEvent) {
    if (this.authorisation.isAdmin(this.organisation)) {
      this.showEditOrg = true
      this.organizationMenuVisible = false
    }

    this.stopEventPropagation(event)
  }

  renameOrganization() {
    const name = this.editOrgForm.get('orgName')?.value
    if (!name) {
      console.warn('organisation name missing')
      return
    }

    this.statusOverlay.showStatus(this.proficloud.statusOverlays.editOrganizationBusy)

    this.iam.setOrganizationName(this.organisation.organizationId, name).subscribe({
      next: (res) => {
        this.showEditOrg = false

        this.statusOverlay.showStatus(this.proficloud.statusOverlays.editOrganizationSuccess)
        this.iam
          .listOrganisations$()
          .pipe(take(1))
          .subscribe({
            next: () => {
              setTimeout(() => {
                this.statusOverlay.resetStatus()
              }, 2000)
            },
          })
      },
      error: (err: HttpErrorResponse) => {
        this.showEditOrg = false
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.editOrganizationError)
        console.log(err)
      },
    })
  }

  ngOnDestroy(): void {
    if (this.getNewUserInvited) {
      this.getNewUserInvited.unsubscribe()
    }
    if (this.membersLoaded) {
      this.membersLoaded.unsubscribe()
    }
  }
}
